@keyframes bounce {
    0%, 100% {
      transform: translateY(-5px);
    }
    50% {
      transform: translateY(-15px);
    }
  }
  
  .animate-bounce:hover {
    animation: bouncer 0.5s infinite;
    box-shadow: 0 15px 10px rgba(0, 0, 0, 0.2);
  }